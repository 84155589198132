.divRegister {
  box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 2vh;
  padding-bottom: 2vh;
}
div.MuiPickersToolbar-toolbar {
  background-color: #857a73;
}
button.MuiPickersDay-daySelected {
  background-color: #857a73;
}
button.MuiPickersDay-daySelected:hover {
  background-color: #857a73;
}
svg.MuiCheckbox-colorPrimary.Mui-checked {
  background-color: #857a73;
}
