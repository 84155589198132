div {
  width: 100%;
  text-align: center;
}

.divLogin {
  box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 2vh;
  padding-bottom: 2vh;
}

label.MuiFormLabel-root.Mui-focused {
  color: #857a73;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #857a73 !important;
  border-width: 2px;
}
