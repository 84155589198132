.divInfo {
  padding: 5px;
}

.sousDivInfo {
  border-style: solid;
  border-color: black;
  border-width: 0.5px;
  border-radius: 5px;
  border-color: darkgray;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: justify;
}

.sousDivContact {
  border-style: solid;
  border-color: black;
  border-width: 0.5px;
  border-radius: 5px;
  border-color: darkgray;
  text-align: justify;
}

.divInfos {
  box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 2vh;
  padding-bottom: 2vh;
}
