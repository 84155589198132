.div {
  min-height: 100%;
  text-align: center;
  width: 100%;
}

input.MuiOutlinedInput-inputAdornedEnd.MuiOutlinedInput-input.MuiInputBase-input {
  width: 250%;
  margin-right: 30%;
}
div.MuiPopover-paper {
  width: fit-content;
}

.divFacture {
  box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 2vh;
  padding-bottom: 2vh;
}
