.divHome {
  /*background-image: url("../Images/running_people.jpg");
    background-size: cover;
    height: auto;*/
}

.titreHome {
  /*color: #3f51b5;*/
  font: small-caps bold 26px/1 sans-serif;
  color: white;
  /*font: bold 1.2em "Fira Sans", serif;*/
}
