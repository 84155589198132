html,
body {
  height: 100%;
  text-align: center;
  text-decoration: none;
  background-image: url("Images/jogging-2343558.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

body {
  display: flex;
  flex-direction: column;
}

.App {
  position: relative;
  min-height: 100vh;
  z-index: 0;
}

header {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.MainScrollArea {
  position: relative;
  z-index: -1;
  padding-bottom: 100px;
  width: 95%;
  margin: auto;
  padding-top: 100px;
  color: black;
}
